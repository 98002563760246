//@ts-nocheck
import React, { useState } from 'react';
import Link from 'next/link';
import Collection_dropdown from "../../components/dropdown/collection_dropdown";
import { trpc } from '../../utils/trpc';
import VerifiedSVG from '../../public/images/svg/verifiedSVG';
import Image from 'next/image';
import ArbitrumLogoSmallSVG from '../../public/images/svg/ArbitrumLogoSmallSVG';
import SingleDark from "../../public/images/SingleNFTPreviewDark.png"
import CollectionDark from "../../public/images/CollectionsImagePreviewDark.png"
import DashboardDark from "../../public/images/DashboardImagePreviewDark.png"



const Collection_items_home = ({ limit }) => {
    const collections = limit ? trpc.collection.collections.useQuery({ limit: limit }) : trpc.collection.collections.useQuery({ limit: 100 });

    const [inputText, setInputText] = useState('');

    let formatter = Intl.NumberFormat('en', { notation: 'compact' });


    function formatNumber(num) {
        if (num >= 1000) {
          return (num / 1000).toFixed(1) + 'k';
        } else if (num >= 100) {
          return num.toFixed(1);
        } else {
          return num.toFixed(2);
        }
      }
    
    
      if (collections.status === 'loading') {
        return( 
            <div className="flex-col max-w-[1620px] p-[1.6875rem] mx-auto mt-4">
                <div className="flex flex-col header mb-12 lg:flex-row">
                    <div className="lg:mr-auto ">
                        <h1 className="font-display text-2xl text-jacarta-700 mb-1 dark:text-white">Collections</h1>
                        <h5 className="font-medium text-2xl dark:text-jacarta-300 text-jacarta-400 mb-1 text-base">Discover NFT collections, ranked by Volume and Sales</h5>
                    </div>
                </div>
                <div className="tab-pane fade">   
                    <div class="animate-pulse">
                    <div className="px-8 mb-2 -mr-3">
                        <div className="flex-1 grid grid-cols-4 sm:grid-cols-6 lg:grid-cols-9 grid-auto-rows-auto gap-[30px] grid-template-rows-1fr grid-auto-columns max-w-1/2 sm:grid-grow">
                            <div className="col-span-3 lg:col-span-3 min-h-0 flex flex-row text-jacarta-900 dark:text-white bg-light-base dark:bg-jacarta-700 animate-pulse dark:animate-pulse w-[70px] h-[15px]">
                               <p className='text-xs font-medium '></p>
                            </div>
                            <p className="font-medium text-xs text-jacarta-900 dark:text-white space-x-4 hidden sm:block min-h-0 bg-light-base dark:bg-jacarta-700 animate-pulse dark:animate-pulse w-[70px] h-[15px]">  </p>
                            <p className="font-medium text-xs text-jacarta-900 dark:text-white space-x-4 hidden lg:block min-h-0 bg-light-base dark:bg-jacarta-700 animate-pulse dark:animate-pulse w-[70px] h-[15px]"> </p>
                            <p className="font-medium text-xs text-jacarta-900 dark:text-white space-x-4 hidden lg:block min-h-0 bg-light-base dark:bg-jacarta-700 animate-pulse dark:animate-pulse w-[70px] h-[15px]">  </p>
                            <p className="font-medium text-xs text-jacarta-900 dark:text-white space-x-4 hidden sm:block min-h-0 bg-light-base dark:bg-jacarta-700 animate-pulse dark:animate-pulse w-[70px] h-[15px]">  </p>
                            <p className="font-medium text-xs text-jacarta-900 dark:text-white space-x-4 hidden lg:block min-h-0 bg-light-base dark:bg-jacarta-700 animate-pulse dark:animate-pulse w-[70px] h-[15px]"> </p>
                            <p className="font-medium text-xs text-jacarta-900 dark:text-white space-x-4 min-h-0 hidden sm:block bg-light-base dark:bg-jacarta-700 animate-pulse dark:animate-pulse w-[70px] h-[15px]"> </p>
                            <p className="font-medium text-xs text-jacarta-900 dark:text-white space-x-4 min-h-0 block sm:hidden bg-light-base dark:bg-jacarta-700 animate-pulse dark:animate-pulse w-[70px] h-[15px]"> </p>
                        </div>
                    </div>
                    {Array.from({ length: 15 }, (_, i) => (<>
                            <div key={i} className="rounded-2.5xl relative items-center bg-light-base dark:bg-jacarta-700 p-8 transition-all duration-300 ease-in-out dark:hover:bg-jacarta-600 hover:bg-jacarta-100 mb-5 border dark:border-jacarta-600 border-jacarta-100 flex-1 grid grid-cols-4 sm:grid-cols-6 lg:grid-cols-9 grid-auto-rows-auto gap-[30px] grid-template-rows-1fr grid-auto-columns max-w-1/2 sm:grid-grow">
                                <div className="col-span-3 lg:col-span-3 min-h-0 flex flex-row text-jacarta-900 dark:text-white bg-light-base dark:bg-jacarta-700 animate-pulse dark:animate-pulse">
                                    <p className='w-[15px] h-[15px] bg-jacarta-100 dark:bg-jacarta-600 rounded-lg my-auto mr-4 -ml-4'></p>
                                    <p className='h-[60px] w-[60px] bg-jacarta-100 dark:bg-jacarta-600 rounded-lg -mr-2'></p>
                                    <div className="ml-5 h-4 w-1/4 rounded hidden sm:block bg-light-base dark:bg-jacarta-600 animate-pulse dark:animate-pulse my-auto ml-6"></div>
                                </div>
                                <div className="ml-5 h-4 w-1/4 rounded hidden sm:block bg-light-base dark:bg-jacarta-600 animate-pulse dark:animate-pulse"></div>
                                <div className="ml-5 h-4 w-1/4 rounded hidden lg:block bg-light-base dark:bg-jacarta-600 animate-pulse dark:animate-pulse"></div>
                                <div className="ml-5 h-4 w-1/4 rounded hidden lg:block bg-light-base dark:bg-jacarta-600 animate-pulse dark:animate-pulse"></div>
                                <div className="ml-5 h-4 w-1/4 rounded hidden sm:block bg-light-base dark:bg-jacarta-600 animate-pulse dark:animate-pulse"></div>
                                <div className="ml-5 h-4 w-1/4 rounded hidden lg:block bg-light-base dark:bg-jacarta-600 animate-pulse dark:animate-pulse"></div>
                                <div className="ml-5 h-4 w-1/4 rounded bg-light-base dark:bg-jacarta-600 animate-pulse dark:animate-pulse"></div>
                            </div>
                        </>
                    ))}
                    </div>
                </div>
            </div>
     );
      }  


    return <>
        {/* <!-- Activity Tab --> */}
        <div className="tab-pane fade max-w-[1620px] p-[1.6875rem] mx-auto pt-10">
            {/* <!-- Records / Filter --> */}
            <div className="flex-col">
                <div className="flex flex-col header mb-12 lg:flex-row">
                    <div className="lg:mr-auto ">
                        <h1 className="font-display text-2xl text-jacarta-700 mb-1 dark:text-white">Collections</h1>
                        <h5 className="font-medium text-2xl dark:text-jacarta-300 text-jacarta-400 mb-1 text-base">Discover NFT collections, ranked by Volume and Sales</h5>
                    </div>
                </div>
                <div className="mb-5 shrink-0 basis-8/12 space-y-5">
                    <div className="px-8 -mb-3 -mr-3">
                        <div className="flex-1 grid grid-cols-4 sm:grid-cols-6 lg:grid-cols-9 grid-auto-rows-auto gap-[30px] grid-template-rows-1fr grid-auto-columns max-w-1/2 sm:grid-grow">
                            <div className="col-span-3 lg:col-span-3 min-h-0 flex flex-row text-jacarta-900 dark:text-white">
                               <p className='text-xs font-medium '> Collection</p>
                            </div>
                            <p className="font-medium text-xs text-jacarta-900 dark:text-white space-x-4 hidden sm:block min-h-0"> Floor </p>
                            <p className="font-medium text-xs text-jacarta-900 dark:text-white space-x-4 hidden lg:block min-h-0"> owners </p>
                            <p className="font-medium text-xs text-jacarta-900 dark:text-white space-x-4 hidden lg:block min-h-0"> Items </p>
                            <p className="font-medium text-xs text-jacarta-900 dark:text-white space-x-4 hidden sm:block min-h-0"> Listed </p>
                            <p className="font-medium text-xs text-jacarta-900 dark:text-white space-x-4 hidden lg:block min-h-0"> Sales </p>
                            <p className="font-medium text-xs text-jacarta-900 dark:text-white space-x-4 min-h-0 hidden sm:block"> Volume </p>
                            <p className="font-medium text-xs text-jacarta-900 dark:text-white space-x-4 min-h-0 block sm:hidden"> Volume </p>
                        </div>
                    </div>
                    {collections.data?.map((collection, index) => {
                        const {
                            title,
                            collectionId,
                            avatarUrl,
                            properties,
                            stats,
                            verified,
                            numberOfOwners,
                            numberOfItems,
                            userImage,
                            itemsCount,
                            userName,
                        } = collection;

                        return (
                            <div key={index}>
                                <div className="collectionRowWrapper relative group">
                                    {/* 
                                    <div className='hidden sm:block'>
                                        <div className="hoverTab absolute flex gap-1 p-1 invisible group-hover:visible z-10 rounded-lg bg-jacarta-100 dark:bg-jacarta-600">
                                            <Link href="/">    
                                                <Image src={SingleDark} alt="NFT 1" className='h-[120px] w-[120px] rounded-l-lg'/>
                                            </Link>
                                            <Link href="/">  
                                                <Image src={CollectionDark} alt="NFT 2" className='h-[120px] w-[120px]'/>
                                            </Link>
                                            <Link href="/">
                                                <Image src={DashboardDark} alt="NFT 3" className='h-[120px] w-[120px] rounded-r-lg'/>
                                            </Link>                                        
                                        </div>
                                    </div> 
                                    */}
                                <Link key={`${collectionId.toLowerCase()}-${index}`}
                                    href={`/collection/${collectionId.toLowerCase()}`}
                                    className="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 rounded-2.5xl relative flex items-center border bg-light-base p-8 dark:hover:bg-jacarta-600 hover:bg-jacarta-100">
                                    <div className="group flex-1 grid grid-cols-4 sm:grid-cols-6 lg:grid-cols-9 grid-auto-rows-auto gap-4 grid-template-rows-1fr grid-auto-columns max-w-1/2 sm:grid-grow">
                                        <div className="col-span-3 lg:col-span-3 min-h-0 flex flex-row">
                                            <h1 className="mr-5 -ml-4 my-auto font-display text-jacarta-700 text-base font-semibold dark:text-white w-[18px]">{index + 1}</h1>
                                            <figure className="self-start  flex group-hover:scale-110 duration-300 ease-out h-[70px] w-[70px]">
                                                <Image
                                                    src={`${avatarUrl}?h=70`}
                                                    height={60}
                                                    width={60}
                                                    className="rounded-2lg my-auto aspect-square h-[60px] w-[60px]"
                                                />
                                                {verified &&
                                                    <div className="relative -top-1 right-[10px] z-10 h-5 w-5">
                                                        <VerifiedSVG />
                                                    </div>
                                                }
                                                </figure>
                                                <div className="max-w-[40%] -ml-2 my-auto">
                                                    <h1 className="font-display ml-3 text-jacarta-700 pl-3 text-xs lg:text-sm dark:text-white">{title}</h1>
                                                    <p className="font-display ml-1 dark:text-jacarta-300 text-jacarta-400 text-xs mt-1 text-base space-x-4 sm:hidden min-h-0 my-auto mt-[3px] pl-5"> Floor&nbsp;Price: {stats?.floorPrice ? (<div className="flex mt-[5px]"><div className="-mt-[3px] -ml-1"><ArbitrumLogoSmallSVG /></div><span className='ml-[3px]'> {stats.floorPrice}</span> </div>) : properties.floorPrice ? (<><span> {properties.floorPrice}</span> <ArbitrumLogoSmallSVG /></>) : ( <div className='mt-[3px] flex flex-row'> <ArbitrumLogoSmallSVG /> N/A </div>)}</p>
                                                </div>
                                        </div>
                                        <p className="font-display text-jacarta-900 text-base dark:text-white space-x-4 hidden sm:block min-h-0 my-auto pl-5"> {stats?.floorPrice ? (<div className="flex"><div className="mr-1"><ArbitrumLogoSmallSVG /></div><span> {stats.floorPrice}</span></div>) : properties.floorPrice ? (<><span> {properties.floorPrice}</span> <ArbitrumLogoSmallSVG /></>) : ( <div className='flex flex-row'> <div className='mr-[3px]'> <ArbitrumLogoSmallSVG /></div> <p className='my-auto'> N/A</p> </div>)}</p>
                                        <p className="font-display text-jacarta-900 text-base dark:text-white space-x-4 hidden lg:block min-h-0 my-auto pl-5"> {properties?.numberOfOwners ? formatter.format(properties.numberOfOwners) : "N/A"} </p>
                                        <p className="font-display text-jacarta-900 text-base dark:text-white space-x-4 hidden lg:block min-h-0 my-auto pl-5"> {properties?.numberOfItems ? formatter.format(properties.numberOfItems) : "N/A"} </p>
                                        <p className="font-display text-jacarta-900 text-base dark:text-white space-x-4 hidden sm:block min-h-0 my-auto pl-5"> {stats?.listed ? formatter.format(stats.listed) : 0} </p>
                                        <p className="font-display text-jacarta-900 text-base dark:text-white space-x-4 hidden lg:block min-h-0 my-auto pl-5"> {stats?.totalSales ? formatter.format(stats.totalSales) : 0} </p>
                                        <p className="font-display text-jacarta-900 text-base dark:text-white space-x-4 min-h-0 hidden sm:block my-auto pl-5"> {stats?.volumeTraded ? ( <div className="flex flex-row"> <div className="flex"><div className="mr-1"><ArbitrumLogoSmallSVG /></div></div> <span>{formatter.format(stats.volumeTraded)}</span> </div> ) : ( <div className='mt-[3px] flex flex-row'> <div className='mr-[3px]'> <ArbitrumLogoSmallSVG /></div> <p className='my-auto'> 0</p> </div>)}</p>
                                        <p className="font-display text-jacarta-900 text-base dark:text-white space-x-4 min-h-0 block sm:hidden my-auto pl-10 -ml-[22px] text-xs"> {stats?.volumeTraded ? ( <div className="flex flex-row"> <div className="flex"><div className="mr-1"><ArbitrumLogoSmallSVG /></div></div> <span className='my-auto'>{formatNumber(stats.volumeTraded)}</span> </div> ) : ( <div className='flex flex-row'><div className='mr-[3px]'> <ArbitrumLogoSmallSVG /></div> <p className='my-auto'> 0</p> </div>)}</p>

                                    </div>
                                </Link>
                            </div>
                        </div>
                        )
                    })}
                </div>
            </div>
        </div>
    </>;
};

export default Collection_items_home;
